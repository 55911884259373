.lists {
  display: flex;
}

.lists .levels {
  flex: 1;
}

.lists .tree {
  flex: 1;
  overflow-x: auto;
}

.lists > .ant-divider {
  margin: 0 1.8rem;
}
