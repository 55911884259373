#root,
body,
html {
  height: 100%;
}

.pane {
  background-color: white;
  padding: 1rem;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
}

.error-link {
  color: #f5222d;
}

.error-link:hover {
  color: #ff4d4f;
}

.full-vertical-divider {
  height: auto !important;
}
